@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Rubik",sans-serif;
}
.searchDiv{
    position: relative;
}

.searchDiv .c-modal> .boader{
    position: absolute;
    background-color: rgb(250, 249, 249);
    width: 290px;
    border-radius: 5px;
    padding: 5px;
    top: 50px;
    box-shadow: -1px -1px 18px -1px rgba(0,0,0,0.75);
}

.searchDiv .c-modal>.boader>.text{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Chat {
  position: relative;
  display: grid;
  grid-template-columns: 22% auto;
  grid-gap: 1rem;
  gap: 1rem;
}

.Left-side-chat {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.Chat-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  background: var(--cardColor);
  border-radius: 1rem;
  padding: 1rem;
  height: auto;
  min-height: 80vh;
  overflow: scroll;
}

.Chat-list {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.Right-side-chat {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.conversation:hover {
  background: #80808038;
  cursor: pointer;
}

.conversation {
  border-radius: 0.5rem;
  padding: 10px;
}

.conversation > div {
  position: relative;
}

.online-dot {
  background-color: greenyellow;
  border-radius: 50%;
  position: absolute;
  left: 2rem;
  width: 1rem;
  height: 1rem;
}


.chatbox-empty-message {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 20px;
}
::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
 .Chat {
    grid-template-columns: 16% auto;
  }
   .follower.conversation>div>.name{
    display: none;
   }
}
.ChatBox-container {
  background: var(--cardColor);
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 14vh 60vh 13vh;
}

.chat-header {
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
 
}

.chat-body {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 1.5rem;
  overflow: scroll;
}

.message {
  /* background: var(--buttonBg); */
  color: rgb(255, 255, 255);
  padding: 0.7rem;
  border-radius: 1rem 1rem 1rem 0;
  max-width: 28rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  background: linear-gradient(98.63deg, #e6ae46 0%, #ed3ca9 100%);
}

.message > span:nth-child(2) {
  font-size: 0.7rem;
  color: var(--textColor);
  align-self: end;
 
}

.own {
  align-self: flex-end;
  border-radius: 1rem 1rem 0 1rem;
  background: linear-gradient(98.63deg, #e6ae46 0%, #ed3ca9 100%);
}

.chat-sender {
  background: rgb(3, 3, 3);
  display: flex;
  
  height: 4.5rem;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.8rem;
  border-radius: 1rem;
  align-self: end;
}
.chat-sender > input {
  height: 70%;
  background-color: rgb(236, 236, 236);
  border-radius: 0.5rem;
  border: none;
  outline: none;
  flex: 1 1;
  font-size: 16px;
  padding: 0px 15px 0px 15px;
}
.chat-sender > div:nth-of-type(1) {
  background: linear-gradient(98.63deg, #f707eb 0%, #ec0f08 100%);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}
.chat-sender > div {
  height: 70%;
  padding: 0px 15px 0px 15px;
}
.send-button{
  cursor: pointer;
  background: linear-gradient(98.63deg, #f707eb 0%, #ec0f08 100%);
  border-radius: 0.5rem;
  font-weight: bold;
}

::-webkit-scrollbar {
  display: none;
}

