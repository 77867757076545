.searchDiv{
    position: relative;
}

.searchDiv .c-modal> .boader{
    position: absolute;
    background-color: rgb(250, 249, 249);
    width: 290px;
    border-radius: 5px;
    padding: 5px;
    top: 50px;
    -webkit-box-shadow: -1px -1px 18px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px -1px 18px -1px rgba(0,0,0,0.75);
    box-shadow: -1px -1px 18px -1px rgba(0,0,0,0.75);
}

.searchDiv .c-modal>.boader>.text{
    display: flex;
    justify-content: space-between;
    align-items: center;
}